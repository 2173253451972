// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.
$typography-font-family: Poppins;

$color: #333333;
$background: #e4e5e6;

$primary: #e2001a;
$secondary: #c8ced3;
$accent: #0251a0;

$red-15: #f70d00;

$grey-15: #97a6ba;

$dark: #1a202e;
$white: #ffffff;

$positive: #21ba45;
$negative: #c10015;
$info: #31ccec;
$warning: #f2c037;

$sidebar-bg: $dark;
$sidebar-color: $grey-15;
$sidebar-active-bg: rgba(0, 0, 0, 0.3);
$sidebar-active-color: $background;
$sidebar-hover-bg: hsla(0, 0%, 100%, 0.12);
$sidebar-hover-color: $sidebar-active-color;
